var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"tag":"section","fluid":""}},[_c('h2',[_vm._v("Ligen")]),_c('v-card',[_c('v-btn',{attrs:{"absolute":"","top":"","right":"","fab":"","small":""},on:{"click":function($event){return _vm.createleague()}}},[_c('v-icon',[_vm._v(" fa-plus ")])],1),_c('v-btn',{staticStyle:{"margin-right":"66px"},attrs:{"absolute":"","top":"","right":"","fab":"","small":""},on:{"click":function($event){return _vm.syncleagues()}}},[_c('span',{staticClass:"fa-stack fa-1x"},[_c('i',{class:{'fa': true,'fa-sync': true,'fa-stack-2x':true,'fa-spin':_vm.sync.leagues.in_progress}}),_c('i',{staticClass:"fa fa-bars fa-stack-1x",staticStyle:{"margin-left":"6px","margin-top":"6px","color":"black","font-size":"140%","text-shadow":"0 0 2px white"}})])]),_c('v-btn',{staticStyle:{"margin-right":"115px"},attrs:{"absolute":"","top":"","right":"","fab":"","small":""},on:{"click":function($event){return _vm.fetchteams()}}},[_c('span',{staticClass:"fa-stack fa-1x"},[_c('i',{class:{'fa': true,'fa-sync': true,'fa-stack-2x':true,'fa-spin':_vm.sync.teams.in_progress}}),_c('i',{staticClass:"fa fa-user-friends fa-stack-1x",staticStyle:{"margin-left":"6px","margin-top":"6px","color":"black","font-size":"140%","text-shadow":"0 0 2px white"}})])]),_c('v-data-table',{attrs:{"headers":[
        { text: 'Reihenfolge', value: 'order', sortable: false, align: 'center' },
        { text: 'Kürzel', value: 'shortName', sortable: false },
        { text: 'Name', value: 'name', sortable: false },
        { text: 'Mannschaften', value: 'teams', sortable: false, align: 'center' },
        { text: 'IDs', value: 'identifiers', sortable: false, align: 'center' },
        { text: 'Duplizieren', value: 'clone', sortable: false, align: 'center' },
        { text: 'Löschen', value: 'delete', sortable: false, align: 'center' }
      ],"items":_vm.EventFind,"mobile-breakpoint":0,"items-per-page":-1,"hide-default-footer":"","sort-by":"order"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_c('base-edit-dialog',{attrs:{"value":item.name || ' ',"id":item._id,"label":"Name"},on:{"input":function (ref) {
            var value = ref.value;
            var id = ref.id;

            return _vm.savefield(id, 'name', value);
      }}})]}},{key:"item.shortName",fn:function(ref){
      var item = ref.item;
return [_c('base-edit-dialog',{attrs:{"value":item.shortName || ' ',"id":item._id,"label":"Kürzel"},on:{"input":function (ref) {
            var value = ref.value;
            var id = ref.id;

            return _vm.savefield(id, 'shortName', value);
      }}})]}},{key:"item.order",fn:function(ref){
      var item = ref.item;
return [_c('base-edit-dialog',{attrs:{"value":item.order || ' ',"id":item._id,"label":"Order"},on:{"input":function (ref) {
            var value = ref.value;
            var id = ref.id;

            return _vm.savefield(id, 'order', value, 'Int');
      }}})]}},{key:"item.teams",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","small":"","fab":""},on:{"click":function($event){return _vm.openteams(item._id, item.teams)}}},[_vm._v(" "+_vm._s((item.teams || []).length)+" ")])]}},{key:"item.clone",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","small":"","fab":""},on:{"click":function($event){return _vm.clone(item._id)}}},[_c('v-icon',[_vm._v(" far fa-clone ")])],1)]}},{key:"item.delete",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","small":"","fab":""},on:{"click":function($event){return _vm.del(item._id)}}},[_c('v-icon',[_vm._v(" far fa-trash-alt ")])],1)]}},{key:"item.identifiers",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","small":"","fab":""},on:{"click":function($event){return _vm.openidentifiers(item, 'StbW2023League')}}},[_vm._v(" "+_vm._s((item.identifiers || []).length)+" ")])]}}])})],1),_c('identifiers',{attrs:{"id":_vm.identifiersdialog.id,"identifiers":_vm.identifiersdialog.identifiers,"collection":_vm.identifiersdialog.collection,"noauto":""},on:{"add":_vm.addidentifier,"del":_vm.delidentifier},model:{value:(_vm.identifiersdialog.open),callback:function ($$v) {_vm.$set(_vm.identifiersdialog, "open", $$v)},expression:"identifiersdialog.open"}}),_c('base-material-dialog',{attrs:{"value":_vm.sync.teams.open,"icon":"far fa-user-friends","title":"Mannschaften hinzufügen","color":"primary","actions":[ 'save', 'close' ]},on:{"close":_vm.teamsclose,"esc":_vm.teamsclose,"save":_vm.teamssave}},[_c('v-data-table',{attrs:{"headers":[
        { text: 'Team-ID', value: 'teamId', sortable: false },
        { text: 'Liga', value: 'league', sortable: false },
        { text: 'Mannschaft', value: 'clubteam', sortable: false },
        { text: 'Speichern', value: 'check', sortable: false, align: 'center' },
        { text: 'Status', value: 'state', sortable: false, align: 'center' } ],"items":_vm.sync.teams.data,"mobile-breakpoint":0,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.league",fn:function(ref){
      var item = ref.item;
return [(item.liga)?[_vm._v(" "+_vm._s(item.liga.name)+" ")]:[_c('v-select',{attrs:{"items":item.leagues,"item-text":"name","item-value":"_id"},model:{value:(item._liga),callback:function ($$v) {_vm.$set(item, "_liga", $$v)},expression:"item._liga"}})]]}},{key:"item.clubteam",fn:function(ref){
      var item = ref.item;
return [(item.clubteam)?[_vm._v(" "+_vm._s(item.clubteam.name)+" ")]:(item.clubteams.length === 0)?[_vm._v(" Keine Mannschaften gefunden ")]:[_c('v-select',{attrs:{"items":item.clubteams,"item-text":"name","item-value":"_id"},model:{value:(item._team),callback:function ($$v) {_vm.$set(item, "_team", $$v)},expression:"item._team"}})]]}},{key:"item.check",fn:function(ref){
      var item = ref.item;
return [(!item.ligateam)?_c('v-checkbox',{model:{value:(item.check),callback:function ($$v) {_vm.$set(item, "check", $$v)},expression:"item.check"}}):_vm._e()]}},{key:"item.state",fn:function(ref){
      var item = ref.item;
return [(item.ligateam)?_c('v-icon',[_vm._v(" far fa-check ")]):(item._liga && item._team && item.check)?_c('v-icon',[_vm._v(" far fa-save ")]):_vm._e()]}}])})],1),_c('base-material-dialog',{attrs:{"value":_vm.teams.open,"icon":"far fa-user-friends","title":"Mannschaften bearbeiten","color":"primary","actions":[ 'close' ]},on:{"close":_vm.closeteams,"esc":_vm.closeteams}},[_c('v-card',{staticClass:"pt-8",attrs:{"flat":""}},[_c('v-btn',{staticClass:"mt-8",attrs:{"absolute":"","top":"","right":"","fab":"","small":""},on:{"click":function () { _vm.addteam.id = null; _vm.addteam.open = true }}},[_c('v-icon',[_vm._v(" fa-plus ")])],1),_c('v-data-table',{attrs:{"items":_vm.teams.teams,"sort-by":"order","items-per-page":-1,"hide-default-footer":"","headers":[
          { text: 'Mannschaft', value: 'team.name', sortable: false },
          { text: 'IDs', value: 'identifiers', sortable: false },
          { text: 'Löschen', value: 'delete', sortable: false }
        ]},scopedSlots:_vm._u([{key:"item.identifiers",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","small":"","fab":""},on:{"click":function($event){return _vm.openidentifiers(item, 'StbW2023LeagueTeam')}}},[_vm._v(" "+_vm._s((item.identifiers || []).length)+" ")])]}},{key:"item.delete",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","small":"","fab":""},on:{"click":function($event){return _vm.delteam(item._team)}}},[_c('v-icon',[_vm._v(" far fa-trash-alt ")])],1)]}}])})],1)],1),_c('base-material-dialog',{attrs:{"value":_vm.addteam.open,"icon":"far fa-user-friends","title":"Mannschaft hinzufügen","color":"primary","actions":[ 'close' ]},on:{"close":function($event){_vm.addteam.open = false},"esc":function($event){_vm.addteam.open = false}}},[_c('base-edit-dialog-mannschaftsuche',{attrs:{"label":"Mannschaft suchen","gender":"w","value":_vm.addteam.id},on:{"input":_vm.teamadd}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }